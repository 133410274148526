import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const pixelRatio = window.devicePixelRatio || 1

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement('canvas')
  tmpCanvas.width = newWidth
  tmpCanvas.height = newHeight

  const ctx = tmpCanvas.getContext('2d')
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight)

  return tmpCanvas
}

function Element({ change, aspect, processo, file }) {
  const [upImg, setUpImg] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState(
    aspect === undefined
      ? { unit: '%', width: 100, height: 100, aspect: undefined }
      : {
          unit: '%',
          width: 100,
          aspect: aspect,
        }
  )
  const [completedCrop, setCompletedCrop] = useState(null)
  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)
  }, [completedCrop])

  useEffect(() => {
    const reader = new FileReader()
    reader.addEventListener('load', () => setUpImg(reader.result))
    reader.readAsDataURL(file)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function generateDownload() {
    if (!completedCrop || !previewCanvasRef.current) {
      return
    }
    const canvas = getResizedCanvas(previewCanvasRef.current, completedCrop.width, completedCrop.height)

    change({ imgSource: canvas.toDataURL('image/png'), width: completedCrop.width, height: completedCrop.height })
  }

  function onModal() {
    change()
  }

  return (
    <Modal isOpen={true} toggle={onModal}>
      <ModalHeader>Editar Imagem</ModalHeader>
      <ModalBody>
        <ReactCrop className={processo === 'Perfil' ? 'cropProfile' : ''} src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
        <canvas className="hidden" ref={previewCanvasRef} />
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={onModal}>
          Cancelar
        </Button>
        <Button color="primary" onClick={generateDownload}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Element
